<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
      <li class="breadcrumb-item active">Diarias</li>
    </ol>
    <ul class="nav nav-tabs justify-content-center" >
      <li class="nav-item">
        <router-link class="nav-link" :class="{ active: $route.path === '/agendamiento/dashboard/diarias' }" to="/agendamiento/dashboard/diarias">Diarias</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :class="{ active: $route.path === '/agendamiento/dashboard/programadas' }" to="/agendamiento/dashboard/programadas">Programadas</router-link>
      </li>
      <!-- Agrega más pestañas según sea necesario -->
    </ul>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header mb-3">Inspecciones Diarias <small>vista general</small></h1>
    <!-- END page-header -->

<!--    <div class="d-sm-flex align-items-center mb-3">-->
<!--      <date-range-picker ref="picker" class="btn btn-dark me-2"-->
<!--                         :opens="dateRange.opens"-->
<!--                         :locale-data="{-->
<!--                           firstDay: 1,-->
<!--                           format: 'DD-MM-YYYY',-->
<!--                           daysOfWeek: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],-->
<!--                           monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],-->
<!--                           applyLabel: 'Aplicar',-->
<!--                           cancelLabel: 'Cancelar',-->
<!--                           fromLabel: 'Desde',-->
<!--                           toLabel: 'Hasta',-->
<!--                         }"-->
<!--                         :ranges="dateRange.ranges"-->
<!--                         :singleDatePicker="dateRange.singleDatePicker"-->
<!--                         :timePicker="dateRange.timePicker"-->
<!--                         :timePicker24Hour="dateRange.timePicker24Hour"-->
<!--                         :showWeekNumbers="dateRange.showWeekNumbers"-->
<!--                         :showDropdowns="dateRange.showDropdowns"-->
<!--                         :autoApply="dateRange.autoApply"-->
<!--                         v-model="dateRange.range"-->
<!--                         @update="updateValues"-->
<!--                         :linkedCalendars="dateRange.linkedCalendars">-->
<!--        <template v-slot:input="picker">-->
<!--          <i class="fa fa-calendar fa-fw text-white text-opacity-50 ms-n1"></i>-->
<!--          {{ picker.startDate | date }} - {{ picker.endDate | date }}-->
<!--          <b class="caret ms-1 opacity-5"></b>-->
<!--        </template>-->
<!--      </date-range-picker>-->
<!--    </div>-->


    <div class="row">
      <div class="col">
        <h6>
          <button id="botonMostrarFiltros" class="btn btn-info btn-sm" style="cursor: pointer;" type="button" @click="mostrarDatePickersFiltros">
            <i class="fa fa-filter"></i>
          </button>
          <label class="form-label mx-2" style="cursor: pointer;" for="botonMostrarFiltros">
            Filtrar Inspecciones Por Fechas
          </label>
        </h6>
      </div>
    </div>
    <div id="divFiltros" class="collapse">
      <div class="row">
        <div class="col-6 col-lg-3 col-xl-2 datepicker">
          <label class="form-label" for="datePickerIni">Fecha Inicio</label>
          <Datepicker
              id="datePickerIni"
              :language="es"
              mondayFirst
              v-model="fechaIniInspDiarias"
              :format="customFormatter"
          ></Datepicker>
        </div>
        <div class="col-6 col-lg-3 col-xl-2 datepicker">
          <label class="form-label" for="datePickerFin">Fecha Fin</label>
          <Datepicker
              id="datePickerFin"
              :language="es"
              mondayFirst
              v-model="fechaFinInspDiarias"
              :format="customFormatter"
              :min-date="fechaIniInspDiarias"
              :max-date="fechaMaxFin"
              :disabled-dates="disabledDates"
          ></Datepicker>
        </div>
        <div class="col-12 col-lg-2 col-xl-2 mb-1 d-flex justify-content-center" style="padding-top: 10px;">
          <button class="btn btn-primary btn-sm align-self-center mx-1" type="button" @click="inspeccionesDiariasRango()"><i class="fa fa-search"></i></button>
          <button class="btn btn-danger btn-sm align-self-center mx-1" type="button" @click="borrarFiltro()"><i class="fa fa-x"></i></button>
        </div>
      </div>
    </div>
    <!--  Gráfico de torta y localidades -->
    <div class="row">
      <!-- BEGIN col-6 -->
      <div class="col-xl-8">
        <!-- BEGIN card -->
        <div class="card border-0 mb-3 overflow-hidden bg-gray-800 text-white">
          <!-- BEGIN card-body -->
          <div class="card-body">
            <!-- BEGIN row -->
            <div class="row">
              <!-- BEGIN col-7 -->
              <div class="col-xl-7">
                <!-- BEGIN title -->
                <div class="mb-3 text-gray-500">
                  <b>INSPECCIONES {{ usandoFiltroFechas ? 'EN FECHAS ESPECIFICADAS':  'EN LA SEMANA'}}</b>
                </div>
                <!-- END title -->
                <!-- BEGIN total-sales -->
                <div class="d-flex mb-1">
                  <h2 class="mb-0">{{ inspDiariasRango.totalInspeccionesRango }}</h2>
                </div>
                <!-- END total-sales -->
                <!-- BEGIN percentage -->
                <div class="mb-3 text-gray-500" v-if="!usandoFiltroFechas">
                  <i v-if="inspDiariasRango.porcentajeCambio >= 0" class="fa fa-caret-up"></i>
                  <i v-else class="fa fa-caret-down"></i>
                  {{ inspDiariasRango.porcentajeCambio }}% comparado con la semana pasada
                </div>
                <!-- END percentage -->
                <hr class="bg-white bg-opacity-50" />
                <!-- BEGIN row -->
                <div class="row text-truncate">
                  <!-- BEGIN col-6 -->
                  <div class="col-6">
                    <div class="fs-12px text-gray-500">Total Inspecciones en el mes actual</div>
                    <div class="fs-18px mb-5px fw-bold">{{ inspDiariasRango.totalInspeccionesMes }}</div>
                    <div class="progress h-5px rounded-3 bg-gray-900 mb-5px">
                      <div class="progress-bar progress-bar-striped rounded-right bg-teal" style="width: 55%"></div>
                    </div>
                  </div>
                  <!-- END col-6 -->
                  <!-- BEGIN col-6 -->
                  <div class="col-6">
                    <div class="fs-12px text-gray-500">Total Inspecciones en el año actual</div>
                    <div class="fs-18px mb-5px fw-bold">{{ inspDiariasRango.totalInspeccionesAnno }}</div>
                    <div class="progress h-5px rounded-3 bg-gray-900 mb-5px">
                      <div class="progress-bar progress-bar-striped rounded-right" style="width: 55%"></div>
                    </div>
                  </div>
                  <!-- END col-6 -->
                </div>
                <!-- END row -->
              </div>
              <!-- END col-7 -->
              <div class="col-xl-5">
                <div class="row">
                  <div class="col">
                    <div id="componenteperso">
                      <div class="flex-grow-1 d-flex align-items-center">
                        <div class="nvd3-inverse-mode" style="height: 180px;">
                          <vn-pie :model="donutChartData.data"
                                  :donut="donutChart.donut"
                                  :legend-position="donutChart.legendPosition"
                                  text-field="label"
                                  value-field="value"
                                  :show-tooltip-percent="true"
                                  :show-legend="false"
                                  :show-labels="false"
                                  ref="pieChart"
                          >
                          </vn-pie>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col" style="align-content: center">
                    <div v-for="(formato, index) in formatosFiltro" :key="formato.formatoId" class="">
                      <input type="checkbox" class="checkboxFormatoFiltro" :class="formatosFiltro[index].clase" :id="'formatoId_'+formato.formatoId" v-model="formatosFiltro[index].valorFiltro" @change="inspeccionesDiariasRango">
                      <label class="form-label mx-2" :for="'formatoId_'+formato.formatoId" style="cursor:pointer; font-weight: bold; font-size: 15px;">{{ formato.nombre }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END row -->
          </div>
          <!-- END card-body -->
        </div>
        <!-- END card -->
      </div>
      <!--END COLUMN 8 -->
      <div class="col-xl-4 col-lg-6">
        <div class="card border-0 mb-3 bg-gray-800 text-white">
          <div class="card-body">
            <div class="mb-2 text-gray-500">
              <b>INSPECCIONES POR LOCALIDAD (TOTAL: {{inspDiariasRango.totalInspeccionesRango}})</b>
            </div>
            <div id="visitors-map" class="mb-2" style="height: 25px">
              <!-- Aquí podría ir un mapa o cualquier otro contenido relevante -->
            </div>
            <div>
              <div v-for="lugar in inspDiariasRango.localidades" :key="lugar.ubicacionId" class="d-flex align-items-center text-white mb-2">
                <div class="d-flex w-100">
                  <div v-if="lugar.ubicacionId" class="mx-1">
                    <input type="checkbox" :id="'filtroUbicacion_'+lugar.ubicacionId" class="form-check-input checkLocalidad" style="cursor: pointer" v-model="localidadesFiltro[lugar.ubicacionId].valorFiltro" @change="inspeccionesDiariasRango">
                    <label class="mx-2" style="cursor: pointer" :for="'filtroUbicacion_'+lugar.ubicacionId">{{ lugar.nombre }}</label>
                  </div>
                  <div class="ms-auto text-gray-500">
                    {{ lugar.porcentaje }}% ({{ lugar.total }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <!-- BEGIN panel -->
        <panel title="Ranking UUTT Rechazados Por Transportista  (Analítica Global)" noButton="true" bodyClass="p-0">
          <!-- Encabezado de las columnas -->
          <div style="display: flex; justify-content: space-between; padding: 10px; font-size: 12px; font-weight: bold; border-bottom: 1px solid #eaeaea;">
            <div>Empresa Transportista</div>
            <div>Total</div>
          </div>
          <!-- Listado de empresas y totales -->
          <div v-if="Object.keys(inspDiariasRango.unidadesRechazadas).length > 0">
            <div v-for="transportista in inspDiariasRango.unidadesRechazadas" :key="transportista.empresaTransportista" class="empresa-transportista" style="display: flex; justify-content: space-between; margin-bottom: 20px; padding: 10px; margin-top: 10px; ">
              <div style="flex: 1;">
                <label class="badge bg-info" style="cursor: pointer; font-size: 14px; " @click="toggleVisibilityUnidad(transportista.empresaTransportista)">
                  {{ transportista.empresaTransportista }}
                </label>
                <div v-show="isVisibleUnidad(transportista.empresaTransportista)" style="margin-top: 15px; ">
                  <div v-for="unidad in transportista.unidades" :key="unidad.patente" class="widget-list rounded-bottom inverse-mode">
                    <div class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
                      <div class="widget-list-media icon">
                        <i class="fa fa-truck bg-red text-white"></i>
                      </div>
                      <div class="widget-list-content">
                        <router-link
                            :to="{ name: 'busquedaAvanzada', query: { [unidad.tipoEquipo == 3 ? 'tracto' : 'equipo']: unidad.id, estado: 4 }}"
                            style="color: black; text-decoration: none;">
                          {{ unidad.patente }}
                        </router-link>
                      </div>
                      <router-link
                          :to="{ name: 'busquedaAvanzada', query: { [unidad.tipoEquipo == 3 ? 'tracto' : 'equipo']: unidad.id, estado: 4 }}"
                          class="btn btn-primary btn-sm"
                          style="margin-right: -10px; margin-bottom: 7px; margin-top: 7px">
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div style="align-self: flex-start; font-size: 14px; margin-right: 10px">
                {{ transportista.unidades.length }}
              </div>
            </div>
          </div>
        </panel>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <panel title="Analítica de Items" bodyClass="p-0" noButton="true">
          <apexchart ref="graficoBarras" type="bar" :options="columnChart.options" :series="columnChart.series" height="250"></apexchart>
        </panel>
      </div>
    </div>
    <!-- BEGIN row -->
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <div class="card border-0 mb-3 text-black">
          <panel title="ITEMES CON MÁS INCUMPLIMIENTOS" noButton="true">
            <div class="card-body scrolleeed"> <!--aqui aplicar overflow-x: scroll para mover hacia el lado-->
              <div v-if="inspDiariasRango.itemsConMasIncumplimientos.topIncumplimientos.length > 0">
                <div v-for="(item, index) in inspDiariasRango.itemsConMasIncumplimientos.topIncumplimientos" :key="index" class="align-items-center mb-15px" style="display: -webkit-box !important; border-bottom: 1px solid #eaeaea;">
                  <div class="widget-img rounded-3 me-10px bg-black p-3px w-30px">
                    <div class="h-100 w-100" style="background: url(/assets/img/flag/truck_red.jpg) center no-repeat; background-size: auto 100%;"></div>
                  </div>
                  <div class="text-truncate">
                    <div>{{ item.itemName }}</div>
                    <div class="text-gray-600">{{ item.formName }}</div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-link text-black ms-1"
                      @click="toggleModalIncumplimientos('inspeccionesItemsIncumplimientos-modal', item.inspecciones)"
                      style="margin-top: -19px"
                  >
                    <i class="fa fa-eye"></i>
                  </button>

                  <div class="ms-auto text-center">
                    <div class="fs-13px" :title="'Inspecciones: ' + item.inspecciones.join(', ')">
                      {{ item.count }}
                    </div>
                    <div class="text-gray-600 fs-10px">no cumple</div>
                  </div>
                </div>
              </div>
              <!-- END product -->
            </div>
          </panel>
        </div>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
    </div>
    <modal :name="'inspeccionesItemsIncumplimientos-modal'" height="auto" class="inspecciones-modal">
      <div class="modal-container inspecciones-container">
        <div class="modal-body" style="text-align: center;">
          <p><strong>Inspecciones en las que este ítem no cumplió:</strong></p>
          <div>
            <vue-good-table
                :columns="columnsInspecciones"
                :rows="inspeccionesItemsIncumplimientosList"
                :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-bind:to="'/showInspeccion/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
              </template>
            </vue-good-table>
          </div>
        </div>
        <div class="button-group mt-4">
          <button type="button" class="btn btn-danger" @click="closeModal('inspeccionesItemsIncumplimientos-modal')">Cerrar</button>
        </div>
      </div>
    </modal>

    <modal :name="'inspeccionesCumplimientos-modal'" height="auto" class="inspecciones-modal">
      <div class="modal-container inspecciones-container">
        <div class="modal-body" style="text-align: center;">
          <p><strong>Inspecciones en las que este ítem no cumplió:</strong></p>
          <div>
            <vue-good-table
                :columns="columnsInspecciones"
                :rows="inspeccionesItemsCumplimientosList"
                :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-bind:to="'/showInspeccion/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
              </template>
            </vue-good-table>
          </div>
        </div>
        <div class="button-group mt-4">
          <button type="button" class="btn btn-danger" @click="closeModal('inspeccionesCumplimientos-modal')">Cerrar</button>
        </div>
      </div>
    </modal>

    <modal :name="'inspeccionesReincidencias-modal'" height="auto" class="inspecciones-modal">
      <div class="modal-container inspecciones-container">
        <div class="modal-body" style="text-align: center;">
          <p><strong>Inspecciones en las que este ítem no cumplió:</strong></p>
          <div>
            <vue-good-table
                :columns="columnsInspecciones"
                :rows="inspeccionesItemsReincidenciasList"
                :pagination-options="{ enabled: true, position: 'bottom' }"
            >
              <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            <router-link v-bind:to="'/showInspeccion/'+ props.row.id" tag="span">
              <span style="font-weight: bold; color: #5aabd1; text-decoration: underline;">
                {{ props.row.id }}
              </span>
            </router-link>
          </span>
              </template>
            </vue-good-table>
          </div>
        </div>
        <div class="button-group mt-4">
          <button type="button" class="btn btn-danger" @click="closeModal('inspeccionesReincidencias-modal')">Cerrar</button>
        </div>
      </div>
    </modal>

    <!-- END row -->
    <!-- BEGIN row -->
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <div class="card border-0 mb-3 text-black">
          <panel title="ITEMES CON MÁS CUMPLIMIENTOS QUE TIENEN OBSERVACIONES" noButton="true">
            <div class="card-body scrolleeed"> <!--aqui aplicar overflow-x: scroll para mover hacia el lado-->
              <!-- BEGIN product -->
              <div v-if="inspDiariasRango.itemsConMasCumplimientos.topcumplimientos.length > 0">
                <div v-for="(item, index) in inspDiariasRango.itemsConMasCumplimientos.topcumplimientos" :key="index" class="align-items-center mb-15px" style="display: -webkit-box !important; border-bottom: 1px solid #eaeaea;">
                  <div class="widget-img rounded-3 me-10px bg-black p-3px w-30px">
                    <div class="h-100 w-100" style="background: url(/assets/img/flag/truck_green.jpg) center no-repeat; background-size: auto 100%;"></div>
                  </div>
                  <div class="text-truncate">
                    <div>{{ item.itemName }}</div>
                    <div class="text-gray-600">{{ item.formName }}</div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-link text-black ms-1"
                      @click="toggleModalCumplimientos('inspeccionesCumplimientos-modal', item.inspecciones)"
                      style="margin-top: -19px"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                  <div class="ms-auto text-center">
                    <div class="fs-13px">{{ item.count }}</div>
                    <div class="text-gray-600 fs-10px">cumple</div>
                  </div>
                </div>
              </div>
              <!-- END product -->
            </div>
          </panel>
        </div>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
    </div>
    <!-- END row -->

    <!-- BEGIN row -->
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <div class="card border-0 mb-3 text-black">
          <panel title="REINCIDENCIAS MÁS COMUNES EN EQUIPOS/TRACTOS" noButton="true">
            <div class="card-body scrolleeed"> <!--aqui aplicar overflow-x: scroll para mover hacia el lado-->
              <!-- BEGIN product -->
              <div v-if="inspDiariasRango.itemsEquiposTractosConMasFallas.topIncumplimientosEquipoYtracto.length > 0">
                <div v-for="(item, index) in inspDiariasRango.itemsEquiposTractosConMasFallas.topIncumplimientosEquipoYtracto" :key="index" class="align-items-center mb-15px" style="display: -webkit-box !important;">
                  <div class="widget-img rounded-3 me-10px bg-black p-3px w-30px">
                    <div class="h-100 w-100" style="background: url(/assets/img/flag/truck_red.jpg) center no-repeat; background-size: auto 100%;"></div>
                  </div>
                  <div class="text-truncate">
                    <div>{{ item.itemName }}</div>
                    <div class="text-gray-600">{{ item.formName }}</div>
                  </div>
                  <button
                      type="button"
                      class="btn btn-link text-black ms-1"
                      @click="toggleModalReincidencias('inspeccionesReincidencias-modal', item.inspecciones)"
                      style="margin-top: -19px"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                  <div class="ms-auto text-center">
                    <div class="fs-13px">{{ item.count }}</div>
                    <div class="text-gray-600 fs-10px">no cumple</div>
                  </div>
                </div>
              </div>
              <!-- END product -->
            </div>
          </panel>
        </div>
        <!-- END card -->
      </div>
      <!-- END col-4 -->
    </div>
    <!-- END row -->

    <div class="row">
      <div class="col-md-12">
        <panel title="Analítica de Vehículos Rechazados" bodyClass="p-0" noButton="true">
          <apexchart ref="graficoBarras2" type="bar" :options="columnChartEquipoTractoFurgoFeeder.options" :series="columnChartEquipoTractoFurgoFeeder.series" height="250"></apexchart>
        </panel>
      </div>
    </div>
    <div class="row">
    <!-- BEGIN col-4 -->
      <div class="col-xl-6 col-lg-6">
      <!-- BEGIN card -->
      <panel :title="`${inspDiariasRango.equipoRechazadoList.length}  Equipos Rechazados (Analítica Global)`" noButton="true" bodyClass="p-0">
        <!-- Contenedor de lista con desplazamiento -->
        <!--            <div style="height: 500px; background-color: white; color: black;">-->
        <!-- Lista de equipos vencidos -->
        <div v-for="equipo in inspDiariasRango.equipoRechazadoList" :key="equipo.id" class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
          <div class="widget-list rounded-bottom inverse-mode">
            <a href="#" class="widget-list-item rounded-0 pt-3px">
              <div class="widget-list-media icon">
                <i class="fa fa-truck bg-red text-white"></i>
              </div>
              <div class="widget-list-content">
                <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" style="color: black; text-decoration: none;">
                  <div class="widget-list-title">{{ equipo.patente }}</div>
                </router-link>
              </div>
              <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" class="btn btn-primary btn-sm" style="margin-right: 10px; margin-bottom: 7px; margin-top: 7px">
                <i class="fa fa-eye"></i>
              </router-link>
            </a>
          </div>
        </div>
        <!--            </div>-->
      </panel>
      <!-- END card -->
    </div>
    <!-- END col-4 -->
      <div class="col-xl-6 col-lg-6">
        <!-- BEGIN card -->
        <panel :title="`${inspDiariasRango.furgonFeederRechazadoList.length}  Equipos Furgones/Feeders Rechazados (Analítica Global)`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; background-color: white; color: black;">-->
          <!-- Lista de equipos vencidos -->
          <div v-for="equipo in inspDiariasRango.furgonFeederRechazadoList" :key="equipo.id" class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
            <div class="widget-list rounded-bottom inverse-mode">
              <a href="#" class="widget-list-item rounded-0 pt-3px">
                <div class="widget-list-media icon">
                  <i class="fa fa-truck bg-red text-white"></i>
                </div>
                <div class="widget-list-content">
                  <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" style="color: black; text-decoration: none;">
                    <div class="widget-list-title">{{ equipo.patente }}</div>
                  </router-link>
                </div>
                <router-link :to="{ name: 'busquedaAvanzada', query: { equipo: equipo.id , estado: 4}}" class="btn btn-primary btn-sm" style="margin-right: 10px; margin-bottom: 7px; margin-top: 7px">
                  <i class="fa fa-eye"></i>
                </router-link>
              </a>
            </div>
          </div>
          <!--            </div>-->
        </panel>
        <!-- END card -->
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <panel title="Analítica Semanal de Tractos" bodyClass="p-0" noButton="true">-->
<!--          <apexchart ref="graficoBarras3" type="bar" :options="columnChartTracto.options" :series="columnChartTracto.series" height="250"></apexchart>-->
<!--        </panel>-->
<!--      </div>-->
<!--    </div>-->
    <!-- BEGIN row -->
    <div class="row">
      <!-- BEGIN col-4 -->
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <panel :title="`${inspDiariasRango.tractoRechazadoList.length} Tractos rechazados (Analítica Global)`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; background-color: white; color: black;"> &lt;!&ndash;para que se desplace poner overflow-y: scroll&ndash;&gt;-->
          <!-- Lista de equipos vencidos -->
          <div v-for="tracto in inspDiariasRango.tractoRechazadoList" :key="tracto.id" class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
            <div class="widget-list rounded-bottom inverse-mode">
              <a href="#" class="widget-list-item rounded-0 pt-3px">
                <div class="widget-list-media icon">
                  <i class="fa fa-truck bg-red text-white"></i>
                </div>
                <div class="widget-list-content">
                  <router-link :to="{ name: 'busquedaAvanzada', query: { tracto: tracto.id , estado: 4}}" style="color: black; text-decoration: none;">
                    <div class="widget-list-title">{{ tracto.patente }}</div>
                  </router-link>
                </div>
                <router-link :to="{ name: 'busquedaAvanzada', query: { tracto: tracto.id, estado: 4 }}" class="btn btn-primary btn-sm" style="margin-right: 10px; margin-bottom: 7px; margin-top: 7px">
                  <i class="fa fa-eye"></i>
                </router-link>
              </a>
            </div>
          </div>
          <!--            </div>-->
          <!-- END card -->
        </panel>
      </div>
      <!-- END col-4 -->

    </div>
    <!-- END row -->

    <!-- BEGIN row -->
    <div class="row">
      <!-- BEGIN col-4 -->
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <panel :title="`${inspDiariasRango.inspeccionEquipoYTracto.length} Inspecciones Rechazadas Por Equipo o Tracto (Analítica Global)`" noButton="true" bodyClass="p-0">
          <!-- Contenedor de lista con desplazamiento -->
          <!--            <div style="height: 500px; background-color: white; color: black;"> &lt;!&ndash;para que se desplace poner overflow-y: scroll&ndash;&gt;-->
          <!-- Lista de equipos vencidos -->

          <div v-for="equipoYtracto in inspDiariasRango.inspeccionEquipoYTracto" :key="equipoYtracto.id" class="widget-list-item rounded-0 pt-3px" style="border-bottom: 1px solid #eaeaea;">
            <div class="widget-list rounded-bottom inverse-mode" style="display: flex; align-items: center;">
                <div class="widget-list-title" style="flex: 1; margin-left: 10px">
                  <router-link :to="{ path: `/showInspeccion/${equipoYtracto.idInspeccion}` }" style="color: black; text-decoration: none;">
                  {{ equipoYtracto.numeroInforme }}
                  </router-link>
                </div>
                <div  class="widget-list-title">
                  Equipo :
                </div>
              <div style="display: flex; align-items: center; flex: 1;">
                <a href="#" class="widget-list-item rounded-0 pt-3px">
                  <!-- Si ambosMalos es true, mostrar ambos rojos -->
                  <div v-if="equipoYtracto.ambosMalos" class="widget-list-media icon">
                    <i class="fa fa-truck bg-red text-white"></i>
                  </div>
                  <!-- Si solo el equipo está rechazado -->
                  <div v-else-if="equipoYtracto.tractoTrueEquipoFalse === false" class="widget-list-media icon">
                    <i class="fa fa-truck bg-red text-white"></i>
                  </div>
                  <!-- Si solo el tracto está rechazado -->
                  <div v-else-if="equipoYtracto.tractoTrueEquipoFalse === true" class="widget-list-media icon">
                    <i class="fa fa-truck bg-green text-white"></i>
                  </div>

                  <div class="widget-list-content">
                    <router-link :to="{ path: `/showInspeccion/${equipoYtracto.idInspeccion}` }" style="color: black; text-decoration: none;">
                      <div class="widget-list-title">{{ equipoYtracto.equipo }}</div>
                    </router-link>
                  </div>
                </a>
              </div>
                <div  class="widget-list-title">
                  Tracto :
                </div>
                <div style="display: flex; align-items: center; flex: 1;">
                  <a href="#" class="widget-list-item rounded-0 pt-3px">
                    <div v-if="equipoYtracto.tractoTrueEquipoFalse === true" class="widget-list-media icon">
                      <i class="fa fa-truck bg-red text-white"></i>
                    </div>
                    <div v-else-if="equipoYtracto.tractoTrueEquipoFalse === false" class="widget-list-media icon">
                      <i class="fa fa-truck bg-green text-white"></i>
                    </div>
                    <div class="widget-list-content">
                      <router-link :to="{ path: `/showInspeccion/${equipoYtracto.idInspeccion}` }" style="color: black; text-decoration: none;">
                        <div  class="widget-list-title">{{ equipoYtracto.tracto }}</div>
                      </router-link>
                    </div>
                  </a>
                </div>
                <div style="flex-shrink: 0; margin-left: auto;">
                  <router-link :to="{ path: `/showInspeccion/${equipoYtracto.idInspeccion}` }" class="btn btn-primary btn-sm" style="margin-right: 10px; margin-bottom: 7px; margin-top: 7px">
                    <i class="fa fa-eye"></i>
                  </router-link>
                </div>
              </div>
            </div>
          <!--            </div>-->
          <!-- END card -->
        </panel>
      </div>
      <!-- END col-4 -->

    </div>
    <!-- END row -->



    <!-- BEGIN row -->
    <div class="row">
      <!-- BEGIN col-4 -->
      <!-- END col-4 -->
      <!-- BEGIN col-4 -->
      <div class="col-xl-12 col-lg-12">
        <!-- BEGIN card -->
        <panel title="Conductores con más inspecciones rechazadas " noButton="true" bodyClass="p-0">
          <!-- BEGIN widget-list -->
          <div class="widget-list rounded-bottom inverse-mode">
            <!-- BEGIN widget-list-item -->
            <a class="widget-list-item rounded-0 pt-3px" v-for="conductor in inspDiariasRango.rankingConductoresRechazados.rankingConductores" :key="conductor.conductorId">
              <div class="widget-list-media icon">
                <i class="fa fa-id-card bg-indigo text-white"></i> <!-- Cambia el color si necesario -->
              </div>
              <div class="widget-list-content">
                <router-link :to="{ name: 'busquedaAvanzada', query: { conductor: conductor.conductorId , estado: 4}}" style="color: black; text-decoration: none;">
                  <div class="widget-list-title">{{ conductor.nombreCompleto }}</div>
                </router-link>
              </div>
              <div class="widget-list-action text-nowrap text-gray-500">
                {{ conductor.frecuencia }}
              </div>
            </a>
            <!-- END widget-list-item -->
          </div>
          <!-- END widget-list -->
        </panel>
      </div>
      <!-- END col-4 -->


      <!-- BEGIN col-4 -->
      <!--      <div class="col-xl-4 col-lg-6">-->
      <!--        &lt;!&ndash; BEGIN card &ndash;&gt;-->
      <!--        <panel title="Cantidad Equipos Inspeccionados por localidad" bodyClass="p-0">-->
      <!--          <div class="table-responsive mb-0">-->
      <!--            <table class="table table-panel align-middle mb-0">-->
      <!--              <thead>-->
      <!--              <tr>-->
      <!--                <th>Localidades</th>-->
      <!--                <th class="text-center">Total</th>-->
      <!--              </tr>-->
      <!--              </thead>-->
      <!--              <tbody>-->
      <!--              <tr v-for="lugar in listLugar" :key="lugar.id">-->
      <!--                <td nowrap>-->
      <!--                  <label class="" style="font-size: 13px">{{ lugar.nombre }}</label>-->
      <!--                </td>-->
      <!--                <td class="text-center">-->
      <!--                  {{ lugar.total }}-->
      <!--                </td>-->
      <!--              </tr>-->
      <!--              </tbody>-->
      <!--            </table>-->
      <!--          </div>-->
      <!--        </panel>-->
      <!--        &lt;!&ndash; END card &ndash;&gt;-->
      <!--      </div>-->
      <!--      &lt;!&ndash; END col-4 &ndash;&gt;-->
    </div>
  </div>
</template>

<script>

import Vue from "vue";
import axios from "axios";
import AppOptions from "@/config/AppOptions.vue";
import {right} from "core-js/internals/array-reduce";
import moment from "moment/moment";
import {es} from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  name: 'DashboardDiarias',
  components:{
    Datepicker
  },
  data() {
    var convertNumberWithCommas = function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    function handleGetDate(minusDate) {
      var d = new Date();
      d = d.setDate(d.getDate() - minusDate);
      return d;
    }
    return {
      columnsInspecciones: [
        { label: "N°", field: "id", sortable: true },
        { label: "Equipo", field: "equipo", sortable: true },
        { label: "Transportista", field: "transportista", sortable: true },
        { label: "Fecha Inspección", field: "fechaInspeccion", sortable: true },
        { label: "Lugar", field: "lugar", sortable: true },
        { label: "Estado", field: "estado", sortable: true },
        { label: "Informe", field: "informe", sortable: true },
        { label: "Inspector", field: "inspector", sortable: true }
      ],
      inspeccionesItemsIncumplimientosList: [],
      inspeccionesItemsCumplimientosList: [],
      inspeccionesItemsReincidenciasList: [],
      //Filtros vvvv
      ubicacionList: [],
      filtrarPorFechas: false,
      usandoFiltroFechas: false,
      es:es,
      fechaIniInspDiarias:'',
      fechaFinInspDiarias:'',
      localidadesFiltro:{
        7  : {nombre: 'RIO LOA'        ,ubicacionId: 7 , valorFiltro: true},
        8  : {nombre: 'PUNTA TEATINO'  ,ubicacionId: 8 , valorFiltro: true},
        11 : {nombre: 'PLANTA PRILLEX' ,ubicacionId: 11, valorFiltro: true},
      },
      localidadesBuscar:[7,8,11],
      formatosFiltro:[
        {nombre: 'Estanques', formatoId: 1, valorFiltro: true, clase: 'checkboxEstanque',color: AppOptions.color.cyan},
        {nombre: 'Furgones' , formatoId: 2, valorFiltro: true, clase: 'checkboxFurgon',  color: AppOptions.color.blue},
        {nombre: 'Ramplas'  , formatoId: 3, valorFiltro: true, clase: 'checkboxRampla',  color: AppOptions.color.purple},
        {nombre: 'Feeder'   , formatoId: 4, valorFiltro: true, clase: 'checkboxFeeder',  color: AppOptions.color.white},
      ],
      formatoTipoBuscar:[1,2,3,4],
      //Filtros ^^^^

      //Inspecciones vvvv
      inspDiariasRango:{
        fechaIniUsada:'', //Las fechas usadas para los filtros.
        fechaFinUsada:'',
        totalInspeccionesRango: 0,
        totalInspeccionesMes:   0,
        totalInspeccionesAnno:  0,
        porcentajeCambio:       0,
        totalInspecciones: 0,
        totalEstanques:    0,
        totalFurgones:     0,
        totalRamplas:      0,
        totalFeeder:       0,
        localidades:[
            {
              ubicacionId: 0,
              nombre:      '',
              total:       0,
              porcentaje:  100,
            },
        ],
        fechasAreaChart:[new Date()],
        fechasColumnChart:[new Date()],
        fechasColumnChartEquipoTractoFurgonFeeder:[new Date()],
        // fechasColumnChartTracto:[new Date()],
        itemsEquiposTractosConMasFallas:{
          topIncumplimientosEquipoYtracto: {},
          itemMasReincidente:              {},
          allReincidenciasPorFecha:        {},
        },
        itemsConMasIncumplimientos:{
          topIncumplimientos:         {},
          itemMasRechazado:           {},
          allItemsRechazadosPorFecha: {},
        },
        itemsConMasCumplimientos:{
          topcumplimientos:          {},
          itemMasAprobadoConObs:     {},
          allItemsCumplidosPorFecha: {},
        },
        unidadesRechazadas:{
          empresaTransportista:{},
          unidades:{
            id:'',
            patente:'',
          }
        },
        rankingConductoresRechazados:{
          rankingConductores:{
            conductorId:    0,
            nombreCompleto: '',
            frecuencia:     0,
          },
          totalInspeccionesRechazadas: 0,
          empresa:                     '',
        },
        inspeccionEquipoYTracto:{
          tracto:null,
          equipo:null,
          numeroInforme:'',
          tractoTrueEquipoFalse: null,
          idInspeccion:null
        },
        equipoRechazadoList: [],
        tractoRechazadoList: [],
        furgonFeederRechazadoList: [],
        equipoRechazadoGraficoList: {
          allEquiposRechazadosPorFecha:{},
        },
        tractoRechazadoGraficoList: {
          allTractosRechazadosPorFecha:{},
        },
        furgonesFeedersRechazadoGraficoList: {
          allFurgonesFeedersRechazadosPorFecha:{},
        },
      },
      //Inspecciones ^^^^

      //Gráficos y otros vvvv
      dateRange: {
        ranges:false,
        opens: 'right',
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: false,
        autoApply: true,
        linkedCalendars: false,
        range: {
          startDate: moment().subtract(7, 'days'),
          endDate: moment(),
        },
      },
      columnChart: {
        options: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Cantidad Items'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex }) {
                if (seriesIndex === 0 || seriesIndex === 1 || seriesIndex === 2) {
                  return val + (val === 1 ? " item" : " items");
                }
                return val;
              }
            }
          },
          colors: ['#FF0000', '#24e624', '#1dd5f1','#FF8C00', '#8B4513', '#00CED1'], // Colores personalizados: rojo, verde, azul
        },
        series: []
      },
      columnChartEquipoTractoFurgoFeeder: {
        options: {
          chart: {
            type: 'bar',
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
            tickPlacement: 'on'
          },
          yaxis: {
            title: {
              text: 'Cantidad Vehículos'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val, { seriesIndex }) {
                if (seriesIndex === 0) {
                  return val + (val === 1 ? " equipo" : " equipos");
                } else if (seriesIndex === 1) {
                  return val + (val === 1 ? " tracto" : " tractos");
                } else if (seriesIndex === 2) {
                  return val + (val === 1 ? " furgón/feeder" : " furgones/feeders");
                }
                return val;
              }
            }
          },
          colors: ['#FF0000', '#FF3D3D', '#FF7474'], // Colores personalizados: rojo, verde, azul
        },
        series: []
      },
      // columnChartTracto: {
      //   options: {
      //     chart: {
      //       type: 'bar',
      //     },
      //     plotOptions: {
      //       bar: {
      //         horizontal: false,
      //         columnWidth: '55%',
      //       },
      //     },
      //     dataLabels: {
      //       enabled: false
      //     },
      //     stroke: {
      //       show: true,
      //       width: 2,
      //       colors: ['transparent']
      //     },
      //     xaxis: {
      //       categories: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
      //       tickPlacement: 'on'
      //     },
      //     yaxis: {
      //       title: {
      //         text: 'Cantidad Tractos'
      //       }
      //     },
      //     fill: {
      //       opacity: 1
      //     },
      //     tooltip: {
      //       y: {
      //         formatter: function (val) {
      //           return val + (val === 1 ? " tracto" : " tractos")
      //         }
      //       }
      //     },
      //     colors: ['#FF0000'], // Colores personalizados: rojo, verde, azul
      //   },
      //   series: []
      // },
      listLugar:[],
      visibleTransportistas: {},
      visibleTransportistasEquipo: {},
      donutChart: {
        donut: true,
        legendPosition:'right',
        data: [
          {
            'label': 'Estanques',
            'value': 100,
            'color': AppOptions.color.cyan,
            'formTipoId': 1,
          }, {
            'label': 'Furgones',
            'value': 30,
            'color': AppOptions.color.blue,
            'formTipoId': 2,
          }, {
            'label': 'Ramplas',
            'value': 30,
            'color': AppOptions.color.purple,
            'formTipoId': 3,
          }, {
            'label': 'Feeder',
            'value': 30,
            'color': AppOptions.color.white,
            'formTipoId': 4,
          }
        ],
      },// Asegúrate de que esto se actualice con los datos del backend
      totalSalesChart: {
        options: {
          chart: {
            type: 'line',
            width: 200,
            height: 36,
            sparkline: {
              enabled: true
            },
            stacked: true
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 1,
              opacityTo: 1,
              colorStops: [{
                offset: 0,
                color: AppOptions.color.blue,
                opacity: 1
              },
                {
                  offset: 100,
                  color: AppOptions.color.indigo,
                  opacity: 1
                }]
            },
          },
          tooltip: {
            theme: 'dark',
            fixed: {
              enabled: false
            },
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return '';
                }
              },
              formatter: (value) => { return convertNumberWithCommas(value) },
            },
            marker: {
              show: false
            }
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: 130
              }
            }
          },{
            breakpoint: 1300,
            options: {
              chart: {
                width: 100
              }
            }
          },{
            breakpoint: 1200,
            options: {
              chart: {
                width: 200
              }
            }
          },{
            breakpoint: 576,
            options: {
              chart: {
                width: 180
              }
            }
          },{
            breakpoint: 400,
            options: {
              chart: {
                width: 120
              }
            }
          }]
        },
        series: [{
          data: []
        }]
      },
      sparkline1: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#ff5b57',
          strokeWidth: 2
        }
      },
      sparkline2: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#f59c1a',
          strokeWidth: 2
        }
      },
      sparkline3: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#00ACAC',
          strokeWidth: 2
        }
      },
      sparkline4: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#348fe2',
          strokeWidth: 2
        }
      },
      sparkline5: {
        data: [ 50,30,45,40,50,20,35,40,90,90,90,90 ],
        length: 12,
        lineStyle: {
          stroke: '#b6c2c9',
          strokeWidth: 2
        }
      },
      sparkline6: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#2d353c',
          strokeWidth: 2
        }
      },
      sparkline7: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#dc3b88',
          strokeWidth: 2
        }
      },
      sparkline8: {
        data: [ 50,30,45,40,50,20,35,40,50,70,90,40 ],
        length: 12,
        lineStyle: {
          stroke: '#54c73f',
          strokeWidth: 2
        }
      },
      areaChart: {
        rightAlignYAxis: false,
        showControls: false,
        clipEdge: false,
        data: [{
          'key' : 'Items rechazados',
          'color' : AppOptions.color.red,
          'values' : [
            { x: handleGetDate(77), y: 13 },{ x: handleGetDate(133), y: 23 }
          ]
        }, {
          'key' : 'Items aprobados con observaciones',
          'color' : AppOptions.color.green,
          'values' : [
            { x: handleGetDate(66), y: 5 },{ x: handleGetDate(76), y: 10}
          ]
        },{
          'key' : 'Reincidencias',
          'color' : AppOptions.color.cyan,
          'values' : [
            { x: handleGetDate(33), y: 21 },{ x: handleGetDate(199), y: 15}
          ]
        }
        ]
      },
      fechaFormateada(fecha){
        if(fecha === '') return moment().format('DD-MM-YYYY')
        else             return moment(fecha).format('DD-MM-YYYY')
      },
      //Gráficos y otros ^^^^
    }
  },
  watch: {
    fechaIniInspDiarias() {
      if (this.fechaFinInspDiarias && new Date(this.fechaFinInspDiarias) > this.fechaMaxFin) {
        this.fechaFinInspDiarias = this.fechaMaxFin;
      }
    }
  },
  computed: {
    fechaMaxFin() {
      if (!this.fechaIniInspDiarias) return null;

      let fechaInicio = new Date(this.fechaIniInspDiarias);
      let fechaMaxima = new Date(fechaInicio);
      fechaMaxima.setMonth(fechaMaxima.getMonth() + 3); // Sumar 3 meses
      return fechaMaxima;
    },
    disabledDates() {
      if (!this.fechaIniInspDiarias) return {}; // Si no hay fecha de inicio, no deshabilitar nada

      const fechaMaxima = new Date(this.fechaIniInspDiarias);
      fechaMaxima.setMonth(fechaMaxima.getMonth() + 3); // Sumar 3 meses

      return {
        customPredictor: (date) => date > fechaMaxima // Deshabilitar fechas mayores a 3 meses después de la fecha inicio
      };
    },
    donutChartData() {
      return {
        donut: true,
        legendPosition: 'right',
        data: [{
          'label': 'Estanques',
          'id': 'labelEstanque',
          'value': this.inspDiariasRango.totalEstanques,
          'color': AppOptions.color.cyan,
          'formTipoId': 1,
        }, {
          'label': 'Furgones',
          'id': 'labelFstanque',
          'value': this.inspDiariasRango.totalFurgones,
          'color': AppOptions.color.blue,
          'formTipoId': 2,
        }, {
          'label': 'Ramplas',
          'id': 'labelRstanque',
          'value': this.inspDiariasRango.totalRamplas,
          'color': AppOptions.color.purple,
          'formTipoId': 3,
        }, {
          'label': 'Feeder',
          'id': 'labelDstanque',
          'value': this.inspDiariasRango.totalFeeder,
          'color': AppOptions.color.white,
          'formTipoId': 4,
        }]
      };
    },
  },
  methods: {
    toggleModalIncumplimientos(idModal, inspecciones) {
      this.inspeccionesItemsIncumplimientosList = inspecciones;
      this.$modal.show(idModal);
    },
    toggleModalCumplimientos(idModal, inspecciones) {
      this.inspeccionesItemsCumplimientosList = inspecciones;
      this.$modal.show(idModal);
    },
    toggleModalReincidencias(idModal, inspecciones) {
      this.inspeccionesItemsReincidenciasList = inspecciones;
      this.$modal.show(idModal);
    },
    closeModal(idModal) {
      this.$modal.hide(idModal);
    },
    async prepareSeriesDataColumnChart() {
      const fechasSemanaActual = this.fechasSemanaActual.map(fecha => new Date(fecha).getTime());

      const prepareData = (items) => {
        const dataByDate = new Map(fechasSemanaActual.map(fecha => [fecha, 0]));

        if (items && items.length > 0) {
          items.forEach(item => {
            const fecha = new Date(item.fechaDeInspeccion);
            fecha.setHours(0, 0, 0, 0);
            const fechaTime = fecha.getTime();
            if (dataByDate.has(fechaTime)) {
              dataByDate.set(fechaTime, (dataByDate.get(fechaTime) || 0) + item.count);
            } else {
              console.warn(`Fecha ${fechaTime} no encontrada en dataByDate`);
            }
          });
        }

        return fechasSemanaActual.map(fecha => dataByDate.get(fecha));
      };

      return [
        {
          name: 'Items rechazados',
          data: prepareData(this.inspDiariasRango.itemsConMasIncumplimientos.allItemsRechazadosPorFecha)
        },
        {
          name: 'Items aprobados con observaciones',
          data: prepareData(this.inspDiariasRango.itemsConMasCumplimientos?.allItemsCumplidosPorFecha)
        },
        {
          name: 'Reincidencias',
          data: prepareData(this.inspDiariasRango.itemsEquiposTractosConMasFallas?.allReincidenciasPorFecha)
        }
      ];
    },
    async prepareSeriesDataColumnChartEquipoTractoFurgonFeeder() {
      const fechasSemanaActual = this.fechasSemanaActual.map(fecha => new Date(fecha).getTime());

      const prepareData = (items) => {
        const dataByDate = new Map(fechasSemanaActual.map(fecha => [fecha, 0]));

        if (items && items.length > 0) {
          items.forEach(item => {
            const fecha = new Date(item.fechaDeInspeccion);
            fecha.setHours(0, 0, 0, 0);
            const fechaTime = fecha.getTime();
            if (dataByDate.has(fechaTime)) {
              dataByDate.set(fechaTime, (dataByDate.get(fechaTime) || 0) + 1);
            }
          });
        }

        return fechasSemanaActual.map(fecha => dataByDate.get(fecha));
      };

      return [
        {
          name: 'Equipos rechazados',
          data: prepareData(this.inspDiariasRango.equipoRechazadoGraficoList?.allEquiposRechazadosPorFecha)
        },
        {
          name: 'Tractos rechazados',
          data: prepareData(this.inspDiariasRango.tractoRechazadoGraficoList?.allTractosRechazadosPorFecha)
        },
        {
          name: 'Furgones/Feeders rechazados',
          data: prepareData(this.inspDiariasRango.furgonesFeedersRechazadoGraficoList?.allFurgonesFeedersRechazadosPorFecha)
        }
      ];
    },
    // async prepareSeriesDataColumnChartTracto() {
    //   const fechasSemanaActual = this.fechasSemanaActual.map(fecha => new Date(fecha).getTime());
    //
    //   const prepareData = (items) => {
    //     const dataByDate = new Map(fechasSemanaActual.map(fecha => [fecha, 0]));
    //
    //     if (items && items.length > 0) {
    //       items.forEach(item => {
    //         const fecha = new Date(item.fechaDeInspeccion);
    //         fecha.setHours(0, 0, 0, 0);
    //         const fechaTime = fecha.getTime();
    //         if (dataByDate.has(fechaTime)) {
    //           dataByDate.set(fechaTime, (dataByDate.get(fechaTime) || 0) + 1);
    //         }
    //       });
    //     }
    //
    //     return fechasSemanaActual.map(fecha => dataByDate.get(fecha));
    //   };
    //
    //   return [
    //     {
    //       name: 'Tractos rechazados',
    //       data: prepareData(this.inspDiariasRango.tractoRechazadoGraficoList?.allTractosRechazadosPorFecha)
    //     }
    //   ];
    // },
    async updateColumnChartData() {
      await this.obtenerFechasSemanaRangoFiltro()
      await this.obtenerFechasSemanaRangoFiltroEquipoTractoFurgonFeeder()
      // await this.obtenerFechasSemanaRangoFiltroTracto()
      this.columnChart.series = await this.prepareSeriesDataColumnChart();
      this.columnChartEquipoTractoFurgoFeeder.series = await this.prepareSeriesDataColumnChartEquipoTractoFurgonFeeder();
      // this.columnChartTracto.series = await this.prepareSeriesDataColumnChartTracto();
    },
    formatDate(d) {
      var monthsName = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
      d = new Date(d);
      d = monthsName[d.getMonth()] + ' ' + d.getDate();
      return d;
    },
    right,
    isVisible(empresaTransportista) {
      return !!this.visibleTransportistas[empresaTransportista];
    },
    async obtenerFechasSemanaRangoFiltro() {
      const hoy = new Date();
      let inicioSemana = new Date(hoy);
      let dia = hoy.getDay();
      if (dia === 0) { // Si hoy es domingo, restar 6 días
        inicioSemana.setDate(hoy.getDate() - 6);
      } else {         // En caso contrario, restar (dia - 1) días para llegar al lunes
        inicioSemana.setDate(hoy.getDate() - (dia - 1));
      }
      inicioSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00

      let finSemana = new Date(inicioSemana);
      finSemana.setDate(inicioSemana.getDate() + 6); // Fin de semana es el domingo
      finSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00

      let fechaIni = new Date(inicioSemana);
      let fechaFin = new Date(finSemana);

      if (this.fechaIniInspDiarias) {
        fechaIni = moment(this.fechaIniInspDiarias).toDate();
        fechaIni.setHours(0, 0, 0, 0);
      }
      if (this.fechaFinInspDiarias) {
        fechaFin = moment(this.fechaFinInspDiarias).toDate();
        fechaFin.setHours(0, 0, 0, 0);
      }

      if (fechaIni > fechaFin) {
        let fechaAux = new Date(fechaIni);
        fechaIni = fechaFin;
        fechaFin = fechaAux;
      }

      let fechas = [];
      while (fechaIni <= fechaFin) {
        fechas.push(new Date(fechaIni).getTime()); // Guardar como timestamp con horas ajustadas a 00:00:00
        fechaIni.setDate(fechaIni.getDate() + 1);
      }
      this.$set(this.inspDiariasRango, 'fechasColumnChart', fechas)
      this.fechasSemanaActual = fechas
      if(!this.fechaIniInspDiarias && !this.fechaFinInspDiarias){
        this.$set(this.columnChart.options.xaxis, 'categories', ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']) // Si no se filtran fechas.
      }else{
        this.$set(this.columnChart.options.xaxis, 'categories', fechas.map((fecha)=> moment(fecha).format('DD-MMM')))
      }
      Vue.nextTick(() => { //Esto porque this.$refs.graficoBarras estaba undefined. Esto espera a que se renderize el DOM.
        if (this.$refs.graficoBarras) {
          this.$refs.graficoBarras.refresh();
        }else{
          console.log("no renderizado")
        }
      })
    },
    async obtenerFechasSemanaRangoFiltroEquipoTractoFurgonFeeder() {
      const hoy = new Date();
      let inicioSemana = new Date(hoy);
      let dia = hoy.getDay();
      if (dia === 0) { // Si hoy es domingo, restar 6 días
        inicioSemana.setDate(hoy.getDate() - 6);
      } else {         // En caso contrario, restar (dia - 1) días para llegar al lunes
        inicioSemana.setDate(hoy.getDate() - (dia - 1));
      }
      inicioSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00

      let finSemana = new Date(inicioSemana);
      finSemana.setDate(inicioSemana.getDate() + 6); // Fin de semana es el domingo
      finSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00

      let fechaIni = new Date(inicioSemana);
      let fechaFin = new Date(finSemana);

      if (this.fechaIniInspDiarias) {
        fechaIni = moment(this.fechaIniInspDiarias).toDate();
        fechaIni.setHours(0, 0, 0, 0);
      }
      if (this.fechaFinInspDiarias) {
        fechaFin = moment(this.fechaFinInspDiarias).toDate();
        fechaFin.setHours(0, 0, 0, 0);
      }

      if (fechaIni > fechaFin) {
        let fechaAux = new Date(fechaIni);
        fechaIni = fechaFin;
        fechaFin = fechaAux;
      }

      let fechas = [];
      while (fechaIni <= fechaFin) {
        fechas.push(new Date(fechaIni).getTime()); // Guardar como timestamp con horas ajustadas a 00:00:00
        fechaIni.setDate(fechaIni.getDate() + 1);
      }
      this.$set(this.inspDiariasRango, 'fechasColumnChartEquipoTractoFurgonFeeder', fechas)
      this.fechasSemanaActual = fechas
      if(!this.fechaIniInspDiarias && !this.fechaFinInspDiarias){
        this.$set(this.columnChartEquipoTractoFurgoFeeder.options.xaxis, 'categories', ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']) // Si no se filtran fechas.
      }else{
        this.$set(this.columnChartEquipoTractoFurgoFeeder.options.xaxis, 'categories', fechas.map((fecha)=> moment(fecha).format('DD-MMM')))
      }
      Vue.nextTick(() => { //Esto porque this.$refs.graficoBarras estaba undefined. Esto espera a que se renderize el DOM.
        if (this.$refs.graficoBarras2) {
          this.$refs.graficoBarras2.refresh();
        }else{
          console.log("no renderizado")
        }
      })
    },
    // async obtenerFechasSemanaRangoFiltroTracto() {
    //   const hoy = new Date();
    //   let inicioSemana = new Date(hoy);
    //   let dia = hoy.getDay();
    //   if (dia === 0) { // Si hoy es domingo, restar 6 días
    //     inicioSemana.setDate(hoy.getDate() - 6);
    //   } else {         // En caso contrario, restar (dia - 1) días para llegar al lunes
    //     inicioSemana.setDate(hoy.getDate() - (dia - 1));
    //   }
    //   inicioSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00
    //
    //   let finSemana = new Date(inicioSemana);
    //   finSemana.setDate(inicioSemana.getDate() + 6); // Fin de semana es el domingo
    //   finSemana.setHours(0, 0, 0, 0); // Ajustar la hora a las 00:00:00
    //
    //   let fechaIni = new Date(inicioSemana);
    //   let fechaFin = new Date(finSemana);
    //
    //   if (this.fechaIniInspDiarias) {
    //     fechaIni = moment(this.fechaIniInspDiarias).toDate();
    //     fechaIni.setHours(0, 0, 0, 0);
    //   }
    //   if (this.fechaFinInspDiarias) {
    //     fechaFin = moment(this.fechaFinInspDiarias).toDate();
    //     fechaFin.setHours(0, 0, 0, 0);
    //   }
    //
    //   if (fechaIni > fechaFin) {
    //     let fechaAux = new Date(fechaIni);
    //     fechaIni = fechaFin;
    //     fechaFin = fechaAux;
    //   }
    //
    //   let fechas = [];
    //   while (fechaIni <= fechaFin) {
    //     fechas.push(new Date(fechaIni).getTime()); // Guardar como timestamp con horas ajustadas a 00:00:00
    //     fechaIni.setDate(fechaIni.getDate() + 1);
    //   }
    //   this.$set(this.inspDiariasRango, 'fechasColumnChartTracto', fechas)
    //   this.fechasSemanaActual = fechas
    //   if(!this.fechaIniInspDiarias && !this.fechaFinInspDiarias){
    //     this.$set(this.columnChartTracto.options.xaxis, 'categories', ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']) // Si no se filtran fechas.
    //   }else{
    //     this.$set(this.columnChartTracto.options.xaxis, 'categories', fechas.map((fecha)=> moment(fecha).format('DD-MMM')))
    //   }
    //   Vue.nextTick(() => { //Esto porque this.$refs.graficoBarras estaba undefined. Esto espera a que se renderize el DOM.
    //     if (this.$refs.graficoBarras3) {
    //       this.$refs.graficoBarras3.refresh();
    //     }else{
    //       console.log("no renderizado")
    //     }
    //   })
    // },
    isVisibleUnidad(empresaTransportista) {
      return !!this.visibleTransportistasEquipo[empresaTransportista];
    },
    toggleVisibility(empresaTransportista) {
      this.$set(this.visibleTransportistas, empresaTransportista, !this.visibleTransportistas[empresaTransportista]);
    },
    toggleVisibilityUnidad(empresaTransportista) {
      this.$set(this.visibleTransportistasEquipo, empresaTransportista, !this.visibleTransportistasEquipo[empresaTransportista]);
    },
    async listaLugar() {
      const api = process.env.VUE_APP_IP_BACKEND + '/ubicacion/listaUbicacion'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      try {
        //PETICION
        let response = await axios.get(api, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.listLugar     = response.data.map(lugar => ({ ...lugar, total: 0 }));
          this.ubicacionList = response.data
        } else {
          this.$showToastError('Algo salio mal !', 'UPS!', this.$notificationSystem().options.error)
        }
      } catch (error) {
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.$showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.$notificationSystem().options.error)
          this.$expirarToken()
        } else {
          this.$showToastError('Intente nuevamente mas tarde !', 'UPS!', this.$notificationSystem().options.error)
        }
      }
    },
    updateValues(d) {
      var startDate = moment(d.startDate);
      var endDate = moment(d.endDate);
      var gap = endDate.diff(startDate, 'days');

      this.dateRange.range.prevStartDate = moment(startDate).subtract('days', gap).format('D MMMM');
      this.dateRange.range.prevEndDate = moment(startDate).subtract('days', 1).format('D MMMM YYYY');
    },
    async totalEquiposInspeccionadosPorLocalidad() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username;
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/vehiculo/totalEquiposInspeccionadosPorLocalidad?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });


        // Asegurarse de que se está accediendo a la parte correcta de la respuesta para obtener el array
        const totalesPorLocalidad = response.data;

        if (Array.isArray(totalesPorLocalidad)) {
          this.listLugar = this.listLugar.map(lugar => {
            const totalEncontrado = totalesPorLocalidad.find(total => total.lugarDeInspeccion.toString() === lugar.id.toString());
            return {
              ...lugar,
              total: totalEncontrado ? totalEncontrado.total : lugar.total
            };
          });
        } else {
          console.error('La respuesta no es un array', totalesPorLocalidad);
        }
      } catch (error) {
        console.error("Error al cargar totales por localidad:", error);
      }
    },
    mostrarDatePickersFiltros(){
      let divFiltros = document.getElementById('divFiltros')
      if(!divFiltros.classList.contains('collapse')){ //si está mostrándose, limpiar los inputs y mostrar los datos por defecto
        this.borrarFiltro()
      }
      document.getElementById('botonMostrarFiltros').classList.toggle('active')
      divFiltros.classList.toggle('collapse')
    },
    borrarFiltro(){
      this.fechaIniInspDiarias = ''
      this.fechaFinInspDiarias = ''
      this.inspeccionesDiariasRango()
      this.filtrarPorFechas = false
      this.usandoFiltroFechas = false
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    //Método para datos en un rango de fechas. Por defecto, usa la semana actual.
    async inspeccionesDiariasRango() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username;
      await this.actualizarLocalidadesBuscar();
      await this.actualizarFormatosBuscar();

      let fechaIni = (this.fechaIniInspDiarias) ? moment(this.fechaIniInspDiarias).format('DD-MM-YYYY') : null;
      let fechaFin = (this.fechaFinInspDiarias) ? moment(this.fechaFinInspDiarias).format('DD-MM-YYYY') : null;

      this.usandoFiltroFechas = (fechaIni != null || fechaFin != null); // Si usa por lo menos una fecha, esto es true
      try {
        const response = await axios.post(
            `${process.env.VUE_APP_IP_BACKEND}/inspeccion/inspeccionesDiariasRango`,
            {
              ubicacionList: (this.localidadesBuscar.length > 0) ? this.localidadesBuscar : 0,
              formatoTipoList: (this.formatoTipoBuscar.length > 0) ? this.formatoTipoBuscar : 0,
            },
            {
              params: {
                username: username,
                fechaIni: fechaIni,
                fechaFin: fechaFin,
              },
              headers: {
                "Authorization": `Bearer ${tokenHeader}`
              }
            }
        );
        if (response.status == 200 || response.status == 201) {
          this.$set(this, 'inspDiariasRango', response.data);
          await this.updateColumnChartData();
        } else {
          this.$showToastError('Error al cargar los datos !', 'UPS!', this.$notificationSystem().options.error);
        }
      } catch (error) {
        console.error("Error al cargar los datos", error);
      }
    },

    async actualizarLocalidadesBuscar(){
      this.localidadesBuscar.splice(0)
      for (let ubicacionId of Object.keys(this.localidadesFiltro)) {
        if(this.localidadesFiltro[ubicacionId].valorFiltro){
          this.localidadesBuscar.push(parseInt(ubicacionId))
        }
      }
    },
    async actualizarFormatosBuscar(){
      this.formatoTipoBuscar.splice(0)
      for (let i = 0; i < this.formatosFiltro.length ; i++) {
        if(this.formatosFiltro[i].valorFiltro){
          this.formatoTipoBuscar.push(this.formatosFiltro[i].formatoId)
        }
      }
    },
  },
  filters: {
    date: function(value) {
      if (value) {
        return moment(String(value)).format('D MMMM YYYY')
      }
    }
  },
  async mounted(){
    await this.listaLugar() // Asegúrate de que este método inicialice cada lugar con un total de 0
    await this.totalEquiposInspeccionadosPorLocalidad()
  },
  created() {
    this.inspeccionesDiariasRango()
  }
}
</script>
<style > //Me dio miedo ponerle scoped a esto por si se echaba otras pantallas.
.table-panel tbody tr {
  height: 55px; /* O el valor que prefieras para la altura de las filas */
}
@media (max-width: 768px) {
  .scrolleeed{
    overflow-x: scroll;
  }
}
.nvd3-inverse-mode svg {
  transform: scale(1.3); /* Ajusta este valor para escalar el gráfico */
}
#componenteperso .nv-legend text{
  fill: white !important;
}
#componenteperso .nv-legendWrap {
  transform: translate(100px, 20px) !important;
}
</style>

<style scoped>
.datepicker input {
  background-color: #ffffff; /* Color de fondo */
  border: 1px solid #cecece; /* Borde */
  border-radius: 4px; /* Redondeo de bordes */
  font-size: 0.75rem; /* Tamaño de fuente */
  padding: 0.4375rem 0.75rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 600;
}
.datepicker input:focus{
  border-color: #495057; /* Color del borde cuando está enfocado */
  box-shadow: 0 0 0 0.2rem rgb(255, 255, 255); /* Sombra exterior opcional para resaltar el foco */
  outline: none;
}
.checkboxFormatoFiltro{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
  width:  1.2em;
  height: 1.2em;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  position: relative;
}
.checkboxEstanque:checked{
  background-color: #49b5d5;
  border-color: #49b5d5;
}
.checkboxFurgon:checked{
  background-color: #348ee1;
  border-color: #348ee1;
}
.checkboxRampla:checked{
  background-color: #5d6790;
  border-color: #5d6790;
}
.checkboxFeeder:checked{
  background-color: #bfc2c4;
  border-color: #bfc2c4;
}
.widget-list .widget-list-item + .widget-list-item .widget-list-content, .widget-list .widget-list-item + .widget-list-item .widget-list-action /deep/{
  border-top: none !important;
}
</style>