<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="javascript:;">Agendamiento</a></li>
      <li class="breadcrumb-item"><a href="javascript:;">Ver</a></li>
      <li class="breadcrumb-item active">Usuario</li>
    </ol>
    <h1 class="page-header">Ver Equipo <small></small></h1>
    <panel title="Formulario de edición de equipo">
        <div class="contenedor" style="display: flex" >
          <div class="cards-container">
            <div class="card-equipos">
              <div class="icon_container-equipos">
                <i v-if="form.sello && form.sello.color === 'red'" class="fa fa-times-circle icono" aria-hidden="true"></i>
                <i v-else-if="form.sello && form.sello.color === 'green'" class="fa fa-check-circle icono" aria-hidden="true"></i>
                <i v-else-if="form.sello && form.sello.color === 'yellow'" class="fa fa-info-circle icono" aria-hidden="true"></i>
                <i v-else class="fa fa-question-circle icono" aria-hidden="true"></i>
              </div>
              <p class="text-before-divider-equipos">
                <template v-if="form.sello && form.sello.color === 'red'">No Apto</template>
                <template v-else-if="form.sello && form.sello.color === 'green'">Cumple</template>
                <template v-else-if="form.sello && form.sello.color === 'yellow'">Requiere Mejoras</template>
                <template v-else>Sin Análisis</template> <!-- Mensaje por defecto cuando el color no es red, green o yellow -->
              </p>
            </div>
            <div class="card-contador">
              <p>Dias: {{ countdown }}</p>
            </div>
          </div>
          <div class="card-equipo">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'infoEquipo' }" @click="changeTab('infoEquipo')"><i class="fa fa-clipboard"></i>Ficha Equipo</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'planMantencion' }" @click="changeTab('planMantencion')"><i class="fa fa-sticky-note" aria-hidden="true"></i>Plan Mantención</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade" :class="{ show: activeTab === 'infoEquipo', active: activeTab === 'infoEquipo' }">
                <div class="formulario-equipos">
                  <form v-on:submit.prevent="" method="POST" autocomplete="off">
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label class="form-label" id="patente"><strong>Patente/Número*</strong> </label>
                          <input class="form-control" type="text" v-model="form.patente" @input="validateInput" />
                        </div>
                        <div class="col-md-6 mb-3">
                          <label for="propietarioDelEquipo" class="form-label"><strong>Propietario*</strong></label>
                          <select class="form-control" id="propietarioDelEquipo" v-model="form.propietarioDelEquipo">
                            <option v-for="propietarios in empresasPropietarias" :value="propietarios.id" :key="propietarios.id">{{ propietarios.razonSocial }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <div class="datepicker" >
                            <label class="form-label" for="vencimientoRevisionTecnica"><strong>Vencimiento Revisión Técnica</strong></label>
                            <Datepicker  :language="es" mondayFirst v-model="form.vencimientoRevisionTecnica" :format="customFormatter" ></Datepicker>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <label for="empresaDeTransporte" class="form-label"><strong>Transportista*</strong></label>
                          <select class="form-control" id="empresaDeTransporte" v-model="form.empresaDeTransporte">
                            <option v-for="transportistas in empresasTransportista" :value="transportistas.id" :key="transportistas.id">{{ transportistas.razonSocial }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="form-label" id="numeroInterno"><strong>Numero Interno</strong> </label>
                            <input class="form-control" type="text" v-model="form.numeroInterno" />
                          </div>
                        <div class="col-md-6 mb-3">
                          <label for="modelo" class="form-label"><strong>Modelo</strong></label>
                          <input class="form-control" type="text" v-model="form.modelo" />
<!--                          <div class="d-flex align-items-center">
                            <select class="form-control" id="modelo" v-model="form.modelo">
                              <option v-for="modelos in equipoModelo" :value="modelos.id" :key="modelos.id">{{ modelos.nombre }}</option>
                            </select>
                            <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-modelo-modal')">+</button>
                          </div>-->
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-md-6 mb-3">
                            <label class="form-label" id="numeroChasis"><strong>Numero de Chasis</strong> </label>
                            <input class="form-control" type="text" v-model="form.numeroChasis" />
                          </div>
                        <div class="col-md-6 mb-3">
                          <label for="tipoEquipamiento" class="form-label"><strong>Tipo Equipamiento</strong></label>
                          <select class="form-control" id="tipoEquipamiento" v-model="form.tipoEquipamiento">
                            <option v-for="tipo in equipoTipoEquipamiento" :value="tipo.id" :key="tipo.id">{{ tipo.nombre }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <div >
                            <label class="form-label" id="kilometraje"><strong>Kilometraje</strong> </label>
                            <input class="form-control" type="number" v-model="form.kilometraje" />
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <label for="fabricante" class="form-label"><strong>Fabricante</strong></label>
                          <select class="form-control" id="fabricante" v-model="form.fabricante">
                            <option v-for="fabricante in empresasFabricantes" :value="fabricante.id" :key="fabricante.id">{{ fabricante.razonSocial }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label class="form-label" id="anoFabricacion"><strong>Año Fabricacion</strong> </label>
                          <input class="form-control" type="text" v-model="form.anoFabricacion" />
                        </div>
                        <div class="col-md-6 mb-3">
                          <label for="faena" class="form-label"><strong>Faena</strong></label>
                          <select class="form-control" id="faena" v-model="form.faena">
                            <option v-for="faena in equipoFaenas" :value="faena.id" :key="faena.id">{{ faena.nombre }}</option>
                          </select>
                        </div>
                      </div>
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <label for="tipoEquipo" class="form-label"><strong>Tipo Equipo</strong></label>
                        <select class="form-control" id="tipoEquipo" v-model="form.tipoEquipo">
                          <option v-for="tipo in tipoEquipos" :value="tipo.id" :key="tipo.id">{{ tipo.nombre }}</option>
                        </select>
                      </div>
                      <div class="col-md-6 mb-3">
                      </div>
                    </div>
                      <form class="form-horizontal form-bordered">
                        <div class="row justify-content-center">
                          <div class="col-md-4 mb-3 text-center">
                            <label class="form-check-label" for="documentacionAlDia">
                              Documentación al Día*
                              <input class="form-check-input" type="checkbox" id="documentacionAlDia" v-model="form.documentacionAlDia">
                            </label>
                          </div>
                          <div class="col-md-4 mb-3 text-center">
                            <label class="form-check-label" for="activo">
                              Activo*
                              <input class="form-check-input" type="checkbox" id="activo" v-model="form.activo">
                            </label>
                          </div>
                        </div>
                      </form>
                      <div class="row">
                        <div class="mb-3 col-sm-2">
                          <button type="button" class="btn btn-secondary" v-on:click="cancelar()">Cancelar</button>
                        </div>
                        <div class="col-sm-8"></div>
                        <div v-if="userRole.includes('ROLE_ADMIN')" class="mb-3 col-sm-2">
                          <button type="button" class="btn btn-primary" v-on:click="editarEquipo()">Editar</button>
                        </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" :class="{ show: activeTab === 'planMantencion', active: activeTab === 'planMantencion' }">
              <div class="formulario-mantencion">
                <form v-on:submit.prevent="" method="POST" autocomplete="off">
                  <h2><u><strong>Ingrese Declaración:</strong></u> <small>Si el periodo ya existe, éste se sobrescribirá con los nuevos datos ingresados.</small></h2>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="mes" class="control-label">Mes <span class="required-indicator">*</span></label>
                      <select class="form-control" id="mes" v-model="form.mes" required>
                        <option value="">- Elija Mes -</option>
                        <option value="1">Enero</option>
                        <option value="2">Febrero</option>
                        <option value="3">Marzo</option>
                        <option value="4">Abril</option>
                        <option value="5">Mayo</option>
                        <option value="6">Junio</option>
                        <option value="7">Julio</option>
                        <option value="8">Agosto</option>
                        <option value="9">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                      </select>
                    </div>

                    <!-- Año del Plan de Mantención -->
                    <div class="col-md-4 mb-3">
                      <label for="anio" class="control-label">Año <span class="required-indicator">*</span></label>
                      <select class="form-control" id="anio" v-model="form.anio" required>
                        <option value="">- Elija Año -</option>
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>
                    </div>

                    <!-- Hubodómetro -->
                    <div class="col-md-4 mb-3">
                      <label for="hubodometro" class="control-label">Hubodómetro <span class="required-indicator">*</span></label>
                      <input class="form-control" type="number" id="hubodometro" v-model="form.hubodometro" required />
                    </div>
                  </div>
                  <div class="row">
                    <!-- Documento Preventivo -->
                    <div class="col-md-6 mb-3">
                      <label for="documentoPreventivo" class="control-label">Documento Preventivo<span class="required-indicator">*</span></label>
                      <input type="file" ref="fileInputPreventivo" name="documentoPreventivo" id="documentoPreventivo" accept="application/pdf" required @change="handleFileUpload($event, 'documentoPreventivo')">                      <span v-if="errors.documentoPreventivo" class="text-danger">Selecciona un archivo Preventivo.</span>
                    </div>

                    <div class="col-md-6 mb-3">
                      <label for="documentoCorrectivo" class="control-label">Documento Correctivo<span class="required-indicator">*</span></label>
                      <input type="file" ref="fileInputCorrectivo" name="documentoCorrectivo" id="documentoCorrectivo" accept="application/pdf" required @change="handleFileUpload($event, 'documentoCorrectivo')">
                      <span v-if="errors.documentoCorrectivo" class="text-danger">Selecciona un archivo Correctivo.</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <label class="form-label" for="observaciones"><strong>Observaciones</strong></label>
                      <textarea class="form-control" id="observaciones" v-model="form.observaciones" rows="3"></textarea>
                    </div>
                  </div>
                    <div class="col-md-12 col-sm-12 col-xs-12" style="margin-top: 10px;">
                      <div class="alert alert-danger" role="alert">
                        <input type="checkbox" name="declaracion" id="declaracion" style="cursor: pointer;" v-model="form.declaracion" />
                        <label for="declaracion" style="cursor: pointer;">Declaro que la información ingresada es fidedigna y comprobable con documento adjunto.</label>
                      </div>
                    </div>
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <button type="button" class="btn btn-primary" @click="guardarPlanMantencion()" :disabled="!form.declaracion">Guardar</button>
                      <button type="button" class="btn btn-warning" @click="limpiarFormulario()">Limpiar</button>
                    </div>
                  </div>

                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Periodo</th>
                      <th>Fecha Subida</th>
                      <th>Hubodómetro</th>
                      <th>Diferencia</th>
                      <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(plan, index) in planMantencion" :key="index">
                      <td>{{ formatFecha(plan.periodo) }}</td>
                      <td>{{ formatFecha2(plan.fechaCreacion) }}</td>
                      <td>{{ plan.hubodometro }}</td>
                      <td>{{ plan.diferencia }}</td>
                      <td>
                      <div>
                        <button type="button" class="btn btn-primary btn-download" @click="metodoGenerarPdf(plan.docPreventivo)">
                          <i class="fa fa-download" style="margin-right: 5px"></i>P
                        </button>
                        <button type="button" class="btn btn-primary btn-download" @click="metodoGenerarPdf(plan.docCorrectivo)">
                          <i class="fa fa-download" style="margin-right: 5px"></i>C
                        </button>
                        <button type="button" class="btn btn-danger btn-delete" @click="abrirModalEliminar(plan)">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-if="!planMantencion || planMantencion.length === 0">
                    <p style="padding-left: 450px">Ningún Plan de Mantención disponible</p>
                  </div>
                  <modal name="eliminarFoto-modal" height="auto">
                    <div class="modal-container-eliminarFoto">
                      <h4>¿Está seguro que quiere eliminar el plan de mantención?</h4>
                      <div class="modal-footer-eliminarFoto">
                        <button type="button" class="btn btn-danger btn-delete" @click="eliminarPlanMantencion(selectedPlan.id)">
                          Eliminar
                        </button>
                        <div class="modal-footer-cerrarFoto">
                          <button class="btn btn-secondary" @click="cerrarModalVerFotos()">Cerrar</button>
                        </div>
                      </div>
                    </div>
                  </modal>
                </form>
              </div>
            </div>
          </div>
        </div>
    </panel>
    <modal name="crear-modelo-modal" height="auto">
      <div class="modal-container">
        <h2>Crear Modelo</h2>
        <div class="col-md-6 mb-3">
          <label for="marca" class="form-label"><strong>Marca</strong></label>
          <div class="d-flex align-items-center">
            <select class="form-control" id="marca" v-model="form.marca">
              <option v-for="marca in marcas" :value="marca.id" :key="marca.id">{{ marca.nombre }}</option>
            </select>
            <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-marca-modal')">+</button>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label for="marca" class="form-label"><strong>Tipo Vehículo</strong></label>
          <div class="d-flex align-items-center">
            <select class="form-control" id="tipoVehiculo" v-model="form.tipoVehiculo">
              <option v-for="tipoVehiculo in tipoVehiculos" :value="tipoVehiculo.id" :key="tipoVehiculo.id">{{ tipoVehiculo.nombre }}</option>
            </select>
            <button class="btn btn-primary btn-sm ms-2" @click="toggleModal('crear-tipoVehiculo-modal')">+</button>
          </div>
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label" id="modeloCreado"><strong>Modelo</strong> </label>
          <input class="form-control mb-3" type="text" v-model="form.modeloCreado" />
        </div>
        <div class="modal-footer justify-content-between"> <!-- Añade una clase para flexbox -->
          <button type="button" class="btn btn-secondary" @click="toggleModal('crear-modelo-modal')">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="crearModelo()">Crear</button>
        </div>
      </div>
    </modal>
    <modal name="crear-marca-modal" height="auto">
      <div class="modal-container">
        <h2>Crear Marca</h2>
        <div class="col-md-6 mb-3 mt-3">
          <label class="form-label" id="marcaCreada"><strong>Marca</strong> </label>
          <input class="form-control mb-3" type="text" v-model="form.marcaCreada" />
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary" @click="toggleModal('crear-marca-modal')">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="crearMarca()">Crear</button>
        </div>
      </div>
    </modal>
    <modal name="crear-tipoVehiculo-modal" height="auto">
      <div class="modal-container">
        <h2>Crear Tipo Vehiculo</h2>
        <div class="col-md-6 mb-3 mt-3">
          <label class="form-label" id="tipoVehiculo"><strong>Tipo Vehiculo</strong> </label>
          <input class="form-control mb-3" type="text" v-model="form.tipoVehiculoCreado" />
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-secondary" @click="toggleModal('crear-tipoVehiculo-modal')">Cerrar</button>
          <button type="button" class="btn btn-primary" @click="crearTipoVehiculo()">Crear</button>
        </div>
      </div>
    </modal>
    <!-- END panel -->
  </div>
</template>


<script>
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import {es} from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    Datepicker
  },
  name: "ShowEquipo",
  data() {
    return {
      userRole: null,
      selectedPlan: null,
      countdown: '',
      planMantencion: [],
      errors: {
        documentoPreventivo: false,
        documentoCorrectivo: false,
      },
      activeTab: 'infoEquipo',
      empresasTransportista:[],
      empresasPropietarias:[],
      empresasFabricantes:[],
      equipoFaenas:[],
      equipoModelo:[],
      equipoTipoEquipamiento:[],
      tipoVehiculos:[],
      marcas:[],
      tipoEquipos:[],
      years: [],
      es: es,
      mondayFirst: true,
      form:{
        tipoEquipo:'',
        documentoPreventivo: null,
        documentoCorrectivo: null,
        declaracion: false,
        mes: null,
        anio: null,
        hubodometro: null,
        "patente":"",
        "kilometraje":"",
        "vencimientoRevisionTecnica": "",
        "numeroChasis": "",
        "numeroInterno": "",
        "anoFabricacion": "",
        documentacionAlDia:false,
        activo:false,
        faena:'',
        sello:null,
        empresaDeTransporte:'',
        propietarioDelEquipo:'',
        fabricante:'',
        modelo:'',
        marca:'',
        tipoEquipamiento:'',
        modeloCreado:'',
        marcaCreada:'',
        tipoVehiculoCreado:'',
        tipoVehiculo:'',
      },
      showDataEquipo:{
      },
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                      {
                        transitionOut: "fadeOutUp",
                        onClosing: function (instance, toast, closedBy) {
                          console.info("closedBy: " + closedBy);
                        }
                      },
                      toast,
                      "buttonName"
                  );
                }
              ]
            ],
            onClosing: function (instance, toast, closedBy) {
              console.info("closedBy: " + closedBy);
            }
          },
          ballon: {
            balloon: true,
            position: "bottomCenter"
          },
          info: {
            position: "bottomLeft"
          },
          success: {
            position: "bottomRight"
          },
          warning: {
            position: "topLeft"
          },
          error: {
            position: "topRight"
          },
        }
      },
    }
  },
  methods:{
    validateInput(event) {
      const input = event.target.value;
      // Elimina todos los caracteres que no sean letras o números
      const cleanedInput = input.replace(/[^a-zA-Z0-9]/g, '');
      // Actualiza el modelo de datos
      this.form.patente = cleanedInput;
    },
    cerrarModalVerFotos() {
      this.$modal.hide('eliminarFoto-modal');
    },
    toggleModal(idModal){
      this.$modal.toggle(idModal)
    },
    abrirModalEliminar(plan) {
      this.selectedPlan = plan; // Guarda el plan seleccionado
      this.$modal.show('eliminarFoto-modal');
    },
    async eliminarPlanMantencion(id) {
      console.log(id)
      this.loadingSpiner();
      const api = process.env.VUE_APP_IP_BACKEND + `/planDeMantencion/delete/` + id;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        let response = await axios.delete(api, { headers: { "Authorization": `Bearer ${tokenHeader}` } });

        if (response.status === 200 || response.status === 201) {
          this.showToastSuccess('Plan de Mantencion eliminado Exitósamente!', 'Genial!', this.notificationSystem.options.success);
          await this.getPlanMantencion();  // Actualiza la lista después de eliminar
          this.cerrarModalVerFotos();  // Cierra el modal automáticamente
        } else {
          this.showToastError('Algo salió mal !', 'UPS!', this.notificationSystem.options.error);
        }
      } catch (error) {
        this.showToastError('Ha ocurrido un error al eliminar el plan de mantencion.', 'Error', this.notificationSystem.options.error);
        console.error(error);
      } finally {
        this.loader.hide();
      }
    },


    limpiarFormulario() {
      // Asegurándose de que mes y anio acepten `null` o una cadena vacía como reseteo
      this.form.documentoPreventivo = null;
      this.form.documentoCorrectivo = null;
      this.form.hubodometro = '';
      this.form.mes = null;
      this.form.anio = null;
      this.form.observaciones = '';
      this.form.declaracion = false;
      this.resetFileInputs();
    },

    resetFileInputs() {
      if (this.$refs.fileInputPreventivo) {
        this.$refs.fileInputPreventivo.value = "";
      }
      if (this.$refs.fileInputCorrectivo) {
        this.$refs.fileInputCorrectivo.value = "";
      }
    },
    metodoGenerarPdf(id) {
      console.log(id)
       // O la variable donde tienes almacenado el ID
      const url = process.env.VUE_APP_IP_BACKEND +`/archivo/descargarArchivo/` + id;
      window.open(url, '_blank');
    },
    formatFecha(fechaISO) {
      const fecha = new Date(fechaISO);
      // Especificar opciones para mostrar solo el mes y el año
      const opciones = { year: 'numeric', month: 'short' };
      return fecha.toLocaleDateString('es-ES', opciones); // Asegúrate de usar 'es-ES' para obtener el nombre del mes en español
    },
    formatFecha2(fechaISO) {
      const fecha = new Date(fechaISO);
      const opciones = {  year: 'numeric', month: '2-digit', day: '2-digit' };
      return fecha.toLocaleDateString('es-ES', opciones);
    },
    async guardarPlanMantencion() {

      const api = `${process.env.VUE_APP_IP_BACKEND}/vehiculo/guardarPlanMantencion`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      this.form.equipoId = this.$route.params.id;
      this.documentoPreventivo = this.form.documentoPreventivo;
      this.documentoCorrectivo = this.form.documentoCorrectivo;
      this.errors.documentoPreventivo = !this.form.documentoPreventivo;
      this.errors.documentoCorrectivo = !this.form.documentoCorrectivo;

      if (!this.form.documentoPreventivo || !this.form.documentoCorrectivo) {
        this.showToastError('Ambos documentos (Preventivo y Correctivo) deben ser cargados.', 'Aviso');
        return;
      }

      if (!this.form.mes || !this.form.anio || !this.form.hubodometro) {
        this.showToastError('Todos los campos obligatorios deben estar llenos.', 'Aviso');
        return;
      }
      this.loadingSpiner()
      try {
        let response = await axios.post(api, this.form, { headers: { "Authorization": `Bearer ${tokenHeader}` } });
        if (response.status === 200 || response.status === 201) {
          this.showToastSuccess('Plan de mantención creado con éxito!', '¡Genial!', this.notificationSystem.options.success);
          // Aquí limpiamos los campos específicos del formulario después de un guardado exitoso
          this.planMantencionId = response.data.id
          this.resetFileInputs();
          this.form.documentoPreventivo = null;
          this.form.documentoCorrectivo = null;
          this.form.hubodometro = '';
          this.form.mes = null;
          this.form.anio = null;
          this.form.observaciones = '';
          this.form.declaracion = false;
          // await this.subirDocumentos();
          await this.getPlanMantencion();  // Actualiza la tabla
        } else {
          this.showToastError('Algo salió mal al intentar guardar el plan.', 'Error', this.notificationSystem.options.error);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error);
          // Manejar el logout o redirección al login
        } else {
          this.showToastError('Error al intentar guardar: ' + error.message, 'Error', this.notificationSystem.options.error);
        }
      } finally {
        this.loader.hide();  // Asegura que el spinner siempre se oculte
      }
    },
    handleFileUpload(event, documentType) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Content = e.target.result.split(',')[1];  // Extraer el contenido base64
          this.form[documentType] = base64Content
        };
        reader.readAsDataURL(file);
      } else {
        this.errors[documentType] = 'Debe cargar un archivo PDF válido.';
      }
    },
    changeTab(tabName) {
      this.activeTab = tabName;
      if (tabName === 'planMantencion') {
        this.getPlanMantencion(); // Carga o recarga los datos de plan mantención si es necesario
      }
    },
    initializeYears() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i <= 30; i++) {
        this.years.push(currentYear - i);
      }
    },
    loadingSpiner() {
      if (this.loader) {
        this.loader.hide();
      }
      this.loader = this.$loading.show({
        canCancel: false,
        loader: 'dots',
        width: 200,
        height: 200,
        color: 'rgb(73,245,214)',
        backgroundColor: 'rgb(152,148,148)',
        opacity: 0.5,
        zIndex: 999,
      });
    },
    customFormatter(date) {
      return date.toLocaleDateString('es-ES');
    },
    async getEmpresasTransportista() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaTransportista?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.empresasTransportista = response.data
      } catch (error) {
        console.error("Error al cargar transportistas:", error);
        return {};
      }
    },
    async getEmpresasFabricantes() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/empresa/empresaFabricante`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.empresasFabricantes = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getEquipoModelo() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/modeloList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.equipoModelo = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getEquipoFaenas() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/faenaList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.equipoFaenas = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async getTipoEquipos() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/tipoEquipoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.tipoEquipos = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async getMarcas() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/marcaList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.marcas = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getEquipoTipoEquipamiento() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/tipoEquipamientoList`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.equipoTipoEquipamiento = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },

    async getEmpresasPropietarias() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      const username = obtenerToken.username
      try {
        const response = await axios.get(`${process.env.VUE_APP_IP_BACKEND}/empresa/empresaPropietaria?username=${username}`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.empresasPropietarias = response.data
      } catch (error) {
        console.error("Error al cargar propietarios:", error);
        return {};
      }
    },
    async getTipoVehiculos() {
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      try {
        const response = await axios.get(process.env.VUE_APP_IP_BACKEND + `/vehiculo/tipoVehiculos`, {
          headers: {
            "Authorization": `Bearer ${tokenHeader}`
          }
        });
        this.tipoVehiculos = response.data;
      } catch (error) {
        console.error("Error al cargar detalles del usuario:", error);
        return {};
      }
    },
    async crearModelo() {
      this.loadingSpiner()
      if (!this.form.marca) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Marca!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.modeloCreado) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Modelo!', 'UPS!', this.notificationSystem.options.error);
        return;
      }
      if (!this.form.tipoVehiculo) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Tipo Vehiculo!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/crearModelo/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        marca: this.form.marca,
        modeloCreado: this.form.modeloCreado,
        tipoVehiculo: this.form.tipoVehiculo,
        equipo: this.$route.params.id,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.showDataEquipo = response.data
          await this.getEquipoModelo()
          await this.getEquipo()
          this.$modal.hide('crear-modelo-modal');
          this.loader.hide()
          this.showToastSuccess('Modelo creado Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async crearMarca() {
      this.loadingSpiner()
      if (!this.form.marcaCreada) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Marca!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/crearMarca/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        marcaCreada: this.form.marcaCreada,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.marca = response.data.marcaCreada.id
          await this.getMarcas()
          this.$modal.hide('crear-marca-modal');
          this.loader.hide()
          this.showToastSuccess('Modelo creado Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async crearTipoVehiculo() {
      this.loadingSpiner()
      if (!this.form.tipoVehiculoCreado) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene TipoVehiculo!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      const api = process.env.VUE_APP_IP_BACKEND + '/vehiculo/creartipoVehiculo/'
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token
      const dataParaEnviar = {
        tipoVehiculo: this.form.tipoVehiculoCreado,
      }
      try {
        let response = await axios.post(api, dataParaEnviar,{headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.marca = response.data.tipoVehiculo.id
          await this.getTipoVehiculos()
          this.$modal.hide('crear-tipoVehiculo-modal');
          this.loader.hide()
          this.showToastSuccess('Modelo creado Exitósamente!', 'Genial!', this.notificationSystem.options.success)
        } else {
          this.loader.hide()
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }
    },
    async getPlanMantencion() {
      if (!this.$route.params.id) return;
      this.loadingSpiner();
      const api = `${process.env.VUE_APP_IP_BACKEND}/vehiculo/showPlanMantencion/${this.$route.params.id}`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;

      try {
        let response = await axios.get(api, { headers: { "Authorization": `Bearer ${tokenHeader}` } });
        if (response.status === 200) {
          // Suponiendo que la fecha de creación es el criterio de ordenación
          let sortedPlanMantencion = response.data.sort((a, b) => {
            return new Date(b.fechaCreacion) - new Date(a.fechaCreacion); // Orden descendente
          });
          this.planMantencion = sortedPlanMantencion;
          this.loader.hide();
        } else {
          this.showToastError('Algo salió mal al cargar el plan de mantenimiento.', 'Error', this.notificationSystem.options.error);
          this.loader.hide();
        }
      } catch (error) {
        this.loader.hide();
        this.showToastError('Error al intentar cargar datos: ' + error.message, 'Error', this.notificationSystem.options.error);
      }
    },
    async getEquipo(){
      this.loadingSpiner()
      // const api = 'http://68.183.108.224:8080/ta-ms-users/user/'
      const api = process.env.VUE_APP_IP_BACKEND + "/vehiculo/showEquipo/"
      const url_data=this.$route.params.id;
      const apiId = api+url_data
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token

      try {
        //PETICION
        let response = await axios.get(apiId, {headers: {"Authorization": `Bearer ${tokenHeader}`}});
        if (response.status == 200 || response.status == 201 ) {
          this.form = response.data
          this.targetDate = new Date(this.form.fechaInspeccionProxima);
          this.updateCountdown(); // Inicializa el contador con la nueva fecha
          this.loader.hide()
        } else {
          this.showToastError('Algo salio mal !', 'UPS!', this.notificationSystem.options.error)
          this.loader.hide()
        }
      } catch (error) {
        this.loader.hide()
        this.error_msg = error
        if (this.error_msg.request.status == 401) {
          this.showToastError('Sesión expirada. Ingrese nuevamente!', 'UPS!', this.notificationSystem.options.error)
          this.$expirarToken()
        } else {
          this.loader.hide()
          this.showToastError('Intente nuevamente mas tarde !', 'UPS!', this.notificationSystem.options.error)
        }
      }

    },

    async editarEquipo(){
      this.loadingSpiner();
      const url = process.env.VUE_APP_IP_BACKEND + `/vehiculo/editarEquipo/${this.$route.params.id}`;
      const obtenerToken = JSON.parse(localStorage.getItem('saveUser'));
      const tokenHeader = obtenerToken.access_token;
      if (!this.form.patente) {
        this.loader.hide(); // Detener el spinner
        this.showToastError('Rellene Patente!', 'UPS!', this.notificationSystem.options.error);
        return;
      }

      try {
        let response = await axios.put(url, this.form, {
          headers: {"Authorization": `Bearer ${tokenHeader}`}
        });
        if (response.status == 200 || response.status == 201) {
          // Manejo del éxito
          this.showToastSuccess('Equipo actualizado con éxito', '¡Éxito!', this.notificationSystem.options.success);
          // Otras acciones necesarias después de una edición exitosa
        } else {
          // Manejo de otros códigos de estado
          this.showToastError('No se pudo actualizar el equipo', 'Error', this.notificationSystem.options.error);
        }
      } catch (error) {
        // Manejo de errores de la petición
        this.showToastError('Error al intentar actualizar: ' + error.message, 'Error', this.notificationSystem.options.error);
      } finally {
        this.loader.hide();
      }
    },

    showToastSuccess(message, title, options) {
      this.$toast.success(
          message,
          title,
          options
      )
    },
    showToastError(message, title, options) {
      this.$toast.error(
          message,
          title,
          options
      )
    },
    cancelar(){
      this.$router.push({path: '/agendamiento/listaEquipo'})
    },
    updateCountdown() {
      const now = new Date();
      const distance = this.targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;

      if (distance < 0) {
        clearInterval(this.interval);
        this.countdown = 'Vencido';
      }
    },
    async loadInitialData() {
      await this.getEmpresasTransportista();
      await this.getEmpresasPropietarias();
      await this.getEquipo();
      await this.getPlanMantencion();
      await this.getEmpresasFabricantes();
      await this.getEquipoModelo();
      await this.getEquipoFaenas();
      await this.getTipoEquipos();
      await this.getEquipoTipoEquipamiento();
      await this.getMarcas();
      await this.getTipoVehiculos();
      let usuario = JSON.parse(localStorage.getItem('saveUser'))
      if(usuario !== null) {
        this.userRole  = usuario["roles"]
      }
      console.log(usuario)
    },
  },
  mounted() {
  },
  created() {
    this.$validarLogin()
    this.loadInitialData()
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000); // Actualizar el contador cada segundo
    this.initializeYears();
  },
}


</script>


<style scoped>
.datepicker input {
  background-color:white;
  color: black;
  border-color: #cecece;
}
.icon_container-equipos i {
  font-size: 6em; /* Aumenta el tamaño de los íconos */
  color: #4a4a4a; /* Color por defecto del ícono, ajusta según necesidad */
}

.icon_container-equipos .fa-times-circle { color: #FF0000; } /* Rojo para rechazado */
.icon_container-equipos .fa-check-circle { color: #008000; } /* Verde para aprobado */
.icon_container-equipos .fa-question-circle { color: rgb(91, 90, 90); } /* Azul para no realizado */
.icon_container-equipos .fa-info-circle{ color: #ffec07; } /* Azul para no realizado */
.icon_container-equipos .fa-spinner { color: #e4f602; } /* Azul para no realizado */

.card-equipos {
  border: 1px solid #d3d3d3; /* Borde gris */
  border-radius: 4px; /* Bordes redondeados */
  padding: 20px;
  margin-right: 120px; /* Margen entre la card y la tabla */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  width: 90%;
  height: 30%;
}

.cards-container {
  display: flex;
  flex-direction: column;
}

.card-contador {
  border: 1px solid #d3d3d3; /* Borde gris */
  border-radius: 4px; /* Bordes redondeados */
  padding: 40px;
  margin-right: 50px; /* Margen entre la card y la tabla */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  width: 90%;
  height: 20%;
  margin-top: 20px;
  font-size: 18px; /* Aumenta el tamaño del texto */
  font-family: 'Roboto', sans-serif;
  text-align: justify;
  color: #262626;
}

.icon_container-equipos {
  flex: none; /* No permite que el contenedor del ícono crezca o se encoja */
  order: -1;
}
.contenedor-principal {
  display: flex;
  flex-direction: row; /* Organiza los hijos en fila */
}
.text-before-divider-equipos {
  font-size: 24px; /* Aumenta el tamaño del texto */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: justify;
  color: #262626;
  margin-top: -5px;
}
.formulario-equipos, .formulario-mantencion {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espacio uniforme entre los elementos del formulario */
}
.row {
  display: flex;
  margin: 0 -10px; /* Restablece el margen para el uso consistente del espacio interno */
}
@media (max-width: 768px) {
  .panel, .card-equipos, .card-contador, .card-equipo {
    width: 100%; /* Ajusta el ancho al 100% de su contenedor */
    max-width: none; /* Elimina cualquier restricción de máximo ancho */
  }
  .icon_container-equipos i {
    font-size: 2em; /* Reduce el tamaño del ícono */
  }
  .modal-container {
    position: fixed;
    top: 120px;
    left: 0; /* Alinea el modal a la izquierda */
    right: 0; /* Alinea el modal a la derecha */
    transform: translate(0%, 0%);
  }
  .card-equipos, .card-contador {
    width: 1%;
  }
  .contenedor {
    flex-direction: column; /* Cambia la disposición de fila a columna */
  }
  .cards-container /deep/{
    margin-bottom: -950px
  }

}

.nav-item {
  margin-bottom: -1px; /* Alinea el borde inferior de las pestañas con el panel de contenido */
}

.nav-link {
  background: #f8f9fa;
  color: #495057;
  padding: 10px 15px;
  display: inline-block;
  border: 1px solid #ccc; /* Borde gris para todos los estados no activos */
  border-bottom: 3px solid transparent; /* Prepara un borde invisible para la pestaña activa */
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
}

.nav-link:hover,
.nav-link:focus {
  color: #007bff; /* Cambia el color del texto a azul */
  background-color: #e9ecef; /* Ligero cambio de fondo para mejor feedback visual */
  text-decoration: underline; /* Subraya el texto */
  border-color: #dee2e6;
}

.nav-link.active {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border-bottom-color: #17a2b8; /* Cambia aquí para hacer el borde del mismo color que el fondo o transparente */
}


.tab-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  gap: 20px; /*
}

.form-control:focus {
  border-color: #17a2b8;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.25);
}
.tab-pane {
  padding: 20px; /* Espacio interno consistente para todo el contenido del panel */
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Alinea los elementos internos para ocupar todo el espacio disponible */
}
.formulario-mantencion .control-label {
  font-weight: bold; /* Ejemplo para darle peso al texto de las etiquetas */
}

/* Estilos para los inputs de archivos */
input[type="file"] {
  width: 100%; /* Asegura que ocupa toda la columna */
}


.card-contador {
  flex: 1; /* Hace que ambos elementos crezcan por igual */
  margin-bottom: 1000px; /* Espacio entre tarjetas */

}

.contenedor {
  display: flex;
  align-items: flex-start; /* Alinea los elementos al principio verticalmente */
  justify-content: flex-start; /* Alinea los elementos al principio horizontalmente */
}

.card-equipos, .card-contador {
  flex: 2;
  min-width: 300px;
  max-width: 400px; /* Ajusta según tus necesidades específicas */
  margin-right: 100px;
}

.card-equipo {
  flex: 2; /* Potencialmente más ancha que las otras tarjetas */
  margin-right: 0; /* No hay margen en el lado derecho */
}
.tab-pane .row {
  display: flex;
  margin-bottom: 15px; /* Añade un margen inferior para separar las filas */
}

.tab-pane .col-md-6 {
  flex: 1; /* Cada columna ocupa un espacio igual dentro de la fila */
  padding-right: 10px; /* Espaciado entre columnas */
  background-color: white;
}

.tab-pane .col-md-6:last-child {
  padding-right: 0; /* Elimina el padding del último elemento para alinear correctamente */
}
.col-md-6 {
  padding: 0 10px; /* Padding uniforme para las columnas */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.label, .form-label {
  margin-bottom: 5px; /* Espacio estándar debajo de las etiquetas para claridad */
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.tab-pane .table {
  margin-top: 70px; /* Añade más espacio en la parte superior de la tabla */
}
.btn-download {
  background-color: #007bff; /* Color azul estándar de Bootstrap para botones */
  color: white;
  margin-right: 10px; /* Añade un margen a la derecha de cada botón */
  border: none; /* Opcional: elimina el borde si prefieres un diseño sin bordes */
}

.btn-download:hover {
  background-color: #0056b3; /* Un azul un poco más oscuro para el hover */
}

/* Estilos adicionales para asegurar que los botones no se envuelven al siguiente línea */
.table td {
  white-space: nowrap;
}
.btn-primary, .btn-warning { /* Asegura que cada botón use casi la mitad del contenedor, ajusta según sea necesario */
  margin: 0 1%; /* Pequeño margen para evitar que los botones se toquen */
}
.nav-link i {
  font-size: 0.9em; /* Reduce el tamaño del ícono para disminuir la congestión visual */
  margin-right: 8px; /* Ajusta el margen derecho para más espacio */
}
.btn-delete {
  margin-left: 30px; /* Agrega un pequeño margen a la izquierda para separar del botón anterior */
}
.modal-footer-eliminarFoto {
  display: flex;
  justify-content: center; /* Centra los botones horizontalmente */
  align-items: center; /* Centra los botones verticalmente */
  gap: 20px; /* Espacio entre los botones */
  padding: 20px; /* Espacio alrededor de los botones dentro del contenedor */
}

.modal-footer-eliminarFoto .btn {
  min-width: 100px; /* Asegura un ancho mínimo para cada botón */
  padding: 10px 10px; /* Añade más espacio dentro de los botones */
}
@media (max-width: 700px) {
  .datepicker /deep/ .vdp-datepicker__calendar {
    left: -15%; /* Centra horizontalmente */
  }
}
</style>
